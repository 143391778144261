body {
    /* padding-top: 56px; */
}

.sticky-offset {
    top: 10%;
}

#body-row {
    margin-left: 0;
    margin-right: 0;
}

#sidebar-container {
    min-height: 100vh;
    background-color: #325D88;
    padding: 0;
}


/* Sidebar sizes when expanded and expanded */

.sidebar-expanded {
    width: 230px;
}

.sidebar-collapsed {
    width: 60px;
}


/* Menu item*/

#sidebar-container .list-group a {
    height: 50px;
    color: white;
}


/* Submenu item*/

#sidebar-container .list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
}

.sidebar-submenu {
    font-size: 0.9rem;
}


/* Separators */

.sidebar-separator-title {
    background-color: #325D88;
    height: 35px;
}

.sidebar-separator {
    background-color: #325D88;
    height: 25px;
}

.logo-separator {
    background-color: #325D88;
    height: 60px;
}


/* Closed submenu icon */

#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
    content: " \f0d7";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}


/* Opened submenu icon */

#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
    content: " \f0da";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

.bg-dark-me {
    background-color: #9bb1de33 !important;
    margin-bottom: 5px;
}

.list-group-item-me {
    border: 0px !important;
}

a.bg-dark-me:hover,
a.bg-dark-me:focus,
button.bg-dark-me:hover,
button.bg-dark-me:focus {
    background-color: #ffffff21 !important;
}

.vrai {
    color: green;
}

.faux {
    color: rgb(218, 66, 29);
}

.mytable th,
.mytable td {
    text-align: center;
}

.menuright {
    background-color: #f9f9f9 !important;
}

.fabuttonSpiner {
    font-size: 15px;
    margin-right: 9px;
}

fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}

#periode5>div {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#example_wrapper {
    width: 100%;
}

.colr {
    margin-top: 5%;
    margin-bottom: 5%;
}


/* .disabled {
    color: #254463 !important;
    background-color: #34618e !important
} */

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.81);
}

.noShowing {
    display: none
}